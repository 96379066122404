import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, inject, Pipe, TransferState, PLATFORM_ID, makeStateKey, isDevMode, makeEnvironmentProviders, APP_INITIALIZER } from '@angular/core';
import * as i3 from '@angular/common';
import { PlatformLocation, isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as i1 from '@angular/router';
import { NavigationEnd, ActivationEnd, RoutesRecognized, provideRouter } from '@angular/router';
import * as i2 from '@jsverse/transloco';
import { provideTranslocoLang, provideTransloco } from '@jsverse/transloco';
import { Subject, BehaviorSubject, filter, buffer, map, takeUntil, of, tap, take } from 'rxjs';
import * as i1$1 from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import * as fs from 'fs';
import * as path from 'path';
import * as nodeUrl from 'url';

/**
 * Placeholder for the current language in translateLoaderOptions.url.
 *
 * translateLoaderOptions: {
 *  url: `/assets/i18n/${LANGUAGE_PLACEHOLDER}.json`,
 * },
 *
 */
const LANGUAGE_PLACEHOLDER = '<<<locale>>>';
const ROUTER_TRANSLATIONS = new InjectionToken('ROUTER_TRANSLATIONS');
const LANGUAGE_OPTIONS = new InjectionToken('LANGUAGE_OPTIONS');
const TRANSLATE_LOADER_OPTIONS = new InjectionToken('TRANSLATE_LOADER_OPTIONS');
class LocalizeService {
  constructor(router, translateService, routerTranslations, languageOptions, location) {
    this.router = router;
    this.translateService = translateService;
    this.routerTranslations = routerTranslations;
    this.languageOptions = languageOptions;
    this.location = location;
    this._destroy$ = new Subject();
    this._currentLanguageSub$ = new BehaviorSubject('');
    /**
     * Current language as Observable.
     */
    this.currentLanguage$ = this._currentLanguageSub$.asObservable();
    this._setNewLocale(this._getCurrentLanguageFromUrl(this.location.path(false)));
    this._subscribeToCurrentPath();
    this._subscribeToLanguageChange();
  }
  get currentLanguage() {
    return this._currentLanguage;
  }
  /**
   * @description
   *
   * Changes the language of the application and navigates to the specified locale's route.
   *
   * @throws `Specified locale not found` error if unknown locale supplied.
   */
  changeLanguage(locale, options = {
    preserveQueryParams: false
  }) {
    if (!this.routerTranslations[locale]) {
      throw new Error(`Specified locale (${locale}) not found!`);
    }
    if (locale === this._currentLanguage) {
      return;
    }
    const extras = options.preserveQueryParams ? {
      queryParamsHandling: 'preserve'
    } : undefined;
    this.router.navigate([this._translateRouteWithParams(this._currentOriginalPath, this.routerTranslations[locale], locale)], extras);
  }
  /**
   * Translates the `path` to the current language.
   *
   * This can be used when navigated from the `.ts` file.
   *
   * @param path array of route segments
   * @param skipNth array of indices that should not be localized. Only works if path was supplied as an array
   */
  translateRouteToCurrentLanguage(path, skipNth = []) {
    return this._translateSegment(path, this.routerTranslations[this._currentLanguage], this._currentLanguage, skipNth);
  }
  /**
   * Translates the `path` to the specified locale.
   *
   * @param path array of route segments
   * @param locale locale to translate to
   * @param skipNth array of indices that should not be localized. Only works if path was supplied as an array
   *
   * @throws `Specified locale not found` error if unknown locale supplied.
   */
  translateRoute(path, locale, skipNth = []) {
    if (!this.routerTranslations[locale]) {
      throw new Error(`Specified locale (${locale}) not found!`);
    }
    return this._translateSegment(path, this.routerTranslations[locale], locale, skipNth);
  }
  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
  _getPrefixForLocale(locale) {
    return this.languageOptions.hideDefaultLanguagePrefix && this.languageOptions.defaultLanguage === locale ? '' : `/${locale}`;
  }
  _getCurrentLanguageFromUrl(url) {
    const pathArr = url.split('?')[0].split('/');
    if (pathArr.length < 2) {
      return this.languageOptions.defaultLanguage;
    }
    if (Object.keys(this.routerTranslations).includes(pathArr[1])) {
      return pathArr[1];
    }
    return this.languageOptions.defaultLanguage;
  }
  _translateRouteWithParams(originalPath, localizedSegments, locale) {
    // Remove queryparams so the router won't encode it
    const currentUrlSplit = this.router.url.split('?')[0].split('/');
    let currentRoute;
    if (this._currentLanguage === this.languageOptions.defaultLanguage && this.languageOptions.hideDefaultLanguagePrefix) {
      currentRoute = currentUrlSplit;
    } else {
      currentRoute = ['', ...(currentUrlSplit.length > 2 ? currentUrlSplit.slice(2) : [''])];
    }
    const translatedUrl = originalPath.split('/').map((segment, idx) => {
      if (segment.charAt(0) === ':') {
        return currentRoute[idx];
      } else if (localizedSegments[segment] === '') {
        return '';
      } else {
        return localizedSegments[segment] || segment;
      }
    }).reduce((prev, curr) => `${prev}/${decodeURIComponent(curr)}`);
    return `${this._getPrefixForLocale(locale)}${translatedUrl}`;
  }
  _translateSegment(path, localizedSegments, locale, skipNth) {
    if (typeof path === 'string') {
      if (path === '/') {
        return this._getPrefixForLocale(locale);
      }
      const translatedRoute = path.split('/').map(segment => {
        if (segment.charAt(0) === '!') {
          return segment.slice(1);
        }
        if (localizedSegments[segment] === '') {
          return '';
        }
        return localizedSegments[segment] || segment;
      }).reduce((prev, curr) => `${prev}/${curr}`);
      return path.charAt(0) === '/' ? `${this._getPrefixForLocale(locale)}/${translatedRoute}` : translatedRoute;
    } else if (typeof path === 'number') {
      return `${path}`;
    } else {
      const routeSegments = [];
      path.forEach((segment, idx) => routeSegments.push(skipNth.includes(idx) ? segment : this._translateSegment(segment, localizedSegments, locale, [])));
      return routeSegments.reduce((prev, curr) => `${prev}/${curr}`);
    }
  }
  _subscribeToCurrentPath() {
    const navigationEnd$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
    this.router.events.pipe(filter(e => e instanceof ActivationEnd), buffer(navigationEnd$), map(([leafNode]) => leafNode.snapshot.data), map(data => {
      return data.localizedRouterData;
    }), takeUntil(this._destroy$)).subscribe(data => {
      this._currentOriginalPath = data.originalPathFromRoot;
      if (this._currentLanguage !== data.currentLocale) {
        this._setNewLocale(data.currentLocale);
      }
    });
  }
  _subscribeToLanguageChange() {
    this.router.events.pipe(filter(e => e instanceof RoutesRecognized), map(e => e), takeUntil(this._destroy$)).subscribe(route => {
      let activatedRoute = route.state.root;
      while (activatedRoute.firstChild) {
        activatedRoute = activatedRoute.firstChild;
      }
      const newLocale = activatedRoute.data['localizedRouterData'].currentLocale;
      if (newLocale !== this._currentLanguage) {
        this._setNewLocale(newLocale);
      }
    });
  }
  _setNewLocale(locale) {
    this._currentLanguage = locale;
    this.translateService.setActiveLang(locale);
    this._currentLanguageSub$.next(locale);
  }
  static {
    this.ɵfac = function LocalizeService_Factory(t) {
      return new (t || LocalizeService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.TranslocoService), i0.ɵɵinject(ROUTER_TRANSLATIONS), i0.ɵɵinject(LANGUAGE_OPTIONS), i0.ɵɵinject(i3.Location));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalizeService,
      factory: LocalizeService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizeService, [{
    type: Injectable
  }], () => [{
    type: i1.Router
  }, {
    type: i2.TranslocoService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ROUTER_TRANSLATIONS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LANGUAGE_OPTIONS]
    }]
  }, {
    type: i3.Location
  }], null);
})();
class LocalizePipe {
  constructor() {
    this.localizeService = inject(LocalizeService);
  }
  transform(path, options = {
    skip: []
  }) {
    const currentLocale = this.localizeService.currentLanguage;
    if (this._lastLocale === currentLocale && this._lastValue === path && this._lastResult !== undefined) {
      return this._lastResult;
    }
    const result = this.localizeService.translateRouteToCurrentLanguage(path, options.skip);
    this._lastLocale = currentLocale;
    this._lastValue = path;
    this._lastResult = result;
    return result;
  }
  static {
    this.ɵfac = function LocalizePipe_Factory(t) {
      return new (t || LocalizePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "localize",
      type: LocalizePipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizePipe, [{
    type: Pipe,
    args: [{
      name: 'localize',
      pure: false,
      standalone: true
    }]
  }], null, null);
})();
class HttpClientWithoutInterceptors extends HttpClient {
  constructor(backend) {
    super(backend);
    this.backend = backend;
  }
  static {
    this.ɵfac = function HttpClientWithoutInterceptors_Factory(t) {
      return new (t || HttpClientWithoutInterceptors)(i0.ɵɵinject(i1$1.HttpBackend));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpClientWithoutInterceptors,
      factory: HttpClientWithoutInterceptors.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpClientWithoutInterceptors, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.HttpBackend
  }], null);
})();
const NG_SERVE_CURRENT_DIR_SEGMENT = '/.angular/vite-root/';
const PRERENDER_CURRENT_DIR_SEGMENT = '/.angular/prerender-root/';
class TranslocoHttpLoader {
  constructor() {
    this.transferState = inject(TransferState);
    this.options = inject(TRANSLATE_LOADER_OPTIONS);
    this.plt = inject(PLATFORM_ID);
    this.http = this.options.skipInterceptors ? inject(HttpClientWithoutInterceptors) : inject(HttpClient);
    this.platformLocation = inject(PlatformLocation);
  }
  getTranslation(lang) {
    const key = makeStateKey(`transfer-translate-${lang}`);
    if (isPlatformBrowser(this.plt)) {
      const translationFromServer = this.transferState.get(key, null);
      // Ha lett elmentve fordítás a TransferState-ben, akkor böngésző oldalon szedje ki onnan a fordításokat
      if (translationFromServer && Object.keys(translationFromServer).length) {
        return of(translationFromServer);
      }
    }
    const url = (typeof this.options.url === 'string' ? this.options.url : isPlatformBrowser(this.plt) ? this.options.url.client : this.options.url.server).replace(LANGUAGE_PLACEHOLDER, lang);
    // If the url does not contain http, it is a local file which we cannot access on the server via httpclient
    if (!url.includes('http') && !isPlatformBrowser(this.plt)) {
      const dirname = nodeUrl.fileURLToPath(import.meta.url);
      // If app is served with ng serve, we can access the files directly with httpclient
      if (isDevMode() && dirname.includes(NG_SERVE_CURRENT_DIR_SEGMENT)) {
        const absolutePath = relativeUrlTransformer(url, this.platformLocation);
        return this.fetchTranslationsViaHttpClient$(absolutePath, key);
      }
      // If app is prerendered, we need to fetch the files via httpclient with relative path
      if (dirname.includes(PRERENDER_CURRENT_DIR_SEGMENT)) {
        return this.fetchTranslationsViaHttpClient$(url, key);
      }
      // If app we are on the server, we need to read the files from the file system
      return this.readTranslationsFromFile$(url, dirname, key);
    }
    return this.fetchTranslationsViaHttpClient$(url, key);
  }
  fetchTranslationsViaHttpClient$(url, transferStateKey) {
    return this.http.get(url, {
      transferCache: false
    }).pipe(map(res => this.options.mapper(res)), tap(translations => {
      if (isPlatformServer(this.plt)) {
        this.transferState.set(transferStateKey, translations);
      }
    }), take(1));
  }
  readTranslationsFromFile$(url, dirname, transferStateKey) {
    const filePath = path.resolve(dirname, `../browser${url}`);
    const translations = fs.existsSync(filePath) ? JSON.parse(fs.readFileSync(filePath, 'utf8')) : null;
    if (translations !== null) {
      this.transferState.set(transferStateKey, translations);
    }
    return of(translations || {});
  }
  static {
    this.ɵfac = function TranslocoHttpLoader_Factory(t) {
      return new (t || TranslocoHttpLoader)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslocoHttpLoader,
      factory: TranslocoHttpLoader.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoHttpLoader, [{
    type: Injectable
  }], null, null);
})();
const relativeUrlTransformer = (url, platformLocation) => {
  const {
    href,
    protocol,
    hostname,
    port
  } = platformLocation;
  let urlPrefix = `${protocol}//${hostname}`;
  if (port) {
    urlPrefix += `:${port}`;
  }
  const baseHref = platformLocation.getBaseHrefFromDOM() || href;
  const baseUrl = new URL(baseHref, urlPrefix);
  const newUrl = new URL(url, baseUrl).toString();
  return newUrl;
};
const hasLoadChildren = loadChildren => {
  return loadChildren !== undefined;
};
const withLocalizedRoutes = localizeConfig => {
  const routes = [...Object.keys(localizeConfig.routerTranslations).map((lang, idx) => ({
    path: idx === 0 && localizeConfig.hideDefaultLanguagePrefix ? '' : lang,
    providers: [provideTranslocoLang(`${lang}|static`)],
    loadChildren: () => localizeConfig.routes().then(routes => generateRoutesForLanguage(routes, localizeConfig.routerTranslations[lang], lang))
  })).reverse(), {
    path: '**',
    redirectTo: localizeConfig.hideDefaultLanguagePrefix ? '' : Object.keys(localizeConfig.routerTranslations)[0]
  }];
  return routes;
};
const generateRoutesForLanguage = (routes, translatedSegments, currentLang, originalPathPrefix = '') => {
  const localizedRoutes = routes.map(route => {
    // PATH
    let translatedRoute;
    if (route.path && route.path !== '' && route.path !== '**') {
      translatedRoute = route.path?.split('/').map(segment => {
        if (segment.charAt(0) === ':') {
          return segment;
        }
        if (segment.charAt(0) === '!') {
          return segment.slice(1);
        }
        if (translatedSegments[segment] === '') {
          return '';
        }
        return translatedSegments[segment] || segment;
      }).reduce((prev, curr) => `${prev}/${curr}`);
    } else {
      translatedRoute = route.path;
    }
    // REDIRECT TO
    let redirectTo = undefined;
    if (route.redirectTo !== undefined) {
      if (route.redirectTo === '') {
        redirectTo = route.redirectTo;
      } else {
        redirectTo = route.redirectTo?.split('/').map(segment => {
          if (segment === '') {
            return segment;
          }
          if (translatedSegments[segment] === '') {
            return '';
          }
          return translatedSegments[segment] || segment;
        }).reduce((prev, curr) => `${prev}/${curr}`);
      }
    }
    const originalPathKey = `${originalPathPrefix}${route.path ? `/${route.path}` : ''}`;
    // LOAD CHILDREN
    let loadChildren = undefined;
    if (hasLoadChildren(route.loadChildren)) {
      const loadCallback = route.loadChildren;
      loadChildren = () => loadCallback().then(routes => generateRoutesForLanguage(routes, translatedSegments, currentLang, originalPathKey));
    }
    return {
      ...route,
      path: translatedRoute,
      ...(redirectTo ? {
        redirectTo
      } : {}),
      ...(route.children ? {
        children: generateRoutesForLanguage(route.children, translatedSegments, currentLang, originalPathKey)
      } : {}),
      ...(loadChildren ? {
        loadChildren
      } : {}),
      data: {
        ...route.data,
        localizedRouterData: {
          originalPathFromRoot: originalPathKey,
          currentLocale: currentLang
        }
      }
    };
  });
  return localizedRoutes;
};

/**
 * Root provider for localized router. It provides the Router with the necessary configuration.
 *
 * @requires HttpClient to be provided beforehand.
 *
 * Default language is the first language in the `routerTranslations` object.
 *
 */
function provideLocalizedRouter(options, ...features) {
  const translocoOptions = {
    loader: TranslocoHttpLoader,
    config: {
      availableLangs: Object.keys(options.routerTranslations),
      reRenderOnLangChange: true,
      defaultLang: Object.keys(options.routerTranslations)[0],
      missingHandler: {
        useFallbackTranslation: false
      }
      // interpolation: ['<<<', '>>>']
    }
  };
  const langOptions = {
    languages: Object.keys(options.routerTranslations),
    defaultLanguage: Object.keys(options.routerTranslations)[0],
    hideDefaultLanguagePrefix: options.hideDefaultLanguagePrefix
  };
  const translateLoaderOptions = {
    url: `/assets/locales/${LANGUAGE_PLACEHOLDER}.json`,
    skipInterceptors: true,
    mapper: res => res,
    ...options.translateLoaderOptions
  };
  const providers = [makeEnvironmentProviders([{
    provide: TRANSLATE_LOADER_OPTIONS,
    useValue: translateLoaderOptions
  }]), ...provideTransloco(translocoOptions), makeEnvironmentProviders([{
    provide: LANGUAGE_OPTIONS,
    useValue: langOptions
  }, {
    provide: ROUTER_TRANSLATIONS,
    useValue: options.routerTranslations
  }, LocalizeService, {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      inject(LocalizeService);
      return () => {};
    }
  }]), provideRouter(withLocalizedRoutes(options), ...features)];
  return providers;
}

/**
 * Generated bundle index. Do not edit.
 */

export { LANGUAGE_PLACEHOLDER, LocalizePipe, LocalizeService, provideLocalizedRouter };
